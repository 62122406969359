<template>
  <div class="">
    <div class="px-3">
      <div class="">
        <label for="button-text" class="editor-label">Text Line 1:</label>
  <input type="text" v-model="block.fileTextLine1" placeholder="Tooltip text" name="button-text" class="editor-input-control w-100">
      </div>
      <div class="mt-2">
        <label for="button-text" class="editor-label">Text Line 2:</label>
  <input type="text" v-model="block.fileTextLine2" placeholder="Tooltip text" name="button-text" class="editor-input-control w-100">
      </div>
      <div class="d-flex justify-content-between mt-2">
        <label for="button-text" class="editor-label"
          >Required:</label
        >
        <b-form-checkbox
          :id="`CheckBoxRequired-${Date.now()}`"
          v-model="block.isRequired"
        ></b-form-checkbox>
      </div>
    
    </div>
    <hr>
    <div class="px-3">
<div class="">
  <label for="" class="editor-label">Max Total File(s) size MB:</label>
  <div class="form-group d-flex align-items-center " >
             <input
               type="range"
               class="form-control-range formControlRange"
               max="50"
               :key="block.maxFileSize+'desktopMAxfileSize'"
               v-model="block.maxFileSize"
             />             
              <span  class="ml-3">{{block.maxFileSize}} </span>
             
           </div>
</div>
<div class="d-flex mt-2 justify-content-between align-items-center">
        <span class="editor-label">Max File Count:</span>
        <div class="select-per-page-count">
          <select
          class="m-0 p-0"
            aria-label="Default select example"
            v-model="block.totalFiles"
          >
          <option v-for="index in 5" :key="index" :value="index" >{{index}}</option>

          </select>
        </div>
      </div>
      </div>
      <hr>

      <div class="px-3">
  <label for="button-text" class="editor-label">Height:</label>
  <div class="d-flex w-100 gap">

<div class="d-flex align-items-center w-100 " title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number" min="0"  v-model="block.style.lineHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.textAlign ></TextAlign>

 </div>
 <hr>
 <div class="px-3">
  <SelectColor :label="'Text Color:'" v-model="block.style.color" opacity.sync="block.style.textOpacity"></SelectColor>
  <SelectColor :label="'Dropbox Hover:'" class="mt-2" :isOpacity="false" v-model="block.style.strokeColor" ></SelectColor>
 </div>
 <hr>
 <div class="px-3 mb-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
 </div>

</div>
</template>
<script>
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
import Padding from "../MiniComponents/Padding.vue";
export default {
  components: {
    TextAlign,
    FontStyles,
    FontFamily,
    SelectColor,
    Padding
  },
  props: {
    block: Object,
    index: Number,
     isDesktopView:Boolean,
  },
  data() {
    return {
     
    };

    //   this.contentLocal = this.content;
  },
  computed:{

textFontFamily(){
  return this.block.style.fontFamily
}
   
  },
  watch:{
    textFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  },
};
</script>
<style scoped>
.editorStyleTitle input
{
      border-color: #B3AFB6;
      font-size: 14px;
}
.editorStyleTitle
{
  font-weight: 400!important;
}
.text-setting select
{
  font-size: 13px;
    padding: 0;
}
.ql-container.ql-snow {
  background: white;
}


input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.PropertyDropdown :deep(.dropdown-toggle){
  background:none;
  color:#000
}
.PropertyDropdown :deep(.dropdown-item:active){
     color: #212529;
    background-color: transparent;
}
.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }
</style>
